export default {
  path: 'setting-round',
  component: { render (c) { return c('router-view') } },
  children: [
    {
      path: 'number-rate',
      name: 'RoundNumbersRate',
      component: () => import('@/views/setting-round/NumbersRate')
    },
    {
      path: 'receive',
      name: 'RoundSetting',
      component: () => import('@/views/setting-round/RoundReceive')
    },
    {
      path: 'market-receive',
      name: 'MarketReceive',
      component: () => import('@/views/setting-round/MarketReceive')
    },
    // {
    //   path: 'lottoset',
    //   name: 'LottosetSetting',
    //   component: () => import('@/views/setting-round/LottosetSetting')
    // },
    {
      path: 'sets',
      name: 'LottosetSetting',
      component: () => import('@/views/setting-round/SetsSetting')
    },
    {
      path: 'sets-receive',
      name: 'SetsReceive',
      component: () => import('@/views/setting-round/SetsReceive')
    },
    {
      path: 'history',
      name: 'SettingHistory',
      component: () => import('@/views/setting-round/SettingHistory')
    }
  ]
}

import AppConfig from '@/configs/app.config.js'

import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Logout = () => import('@/views/pages/Logout')

Vue.use(Router)

const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

import ManageAccountRouter from '@/router/manage-account'
import AccountSettingRouter from '@/router/account-setting'
import SettingReportRouter from '@/router/setting-report'
import WinLoseReportRouter from '@/router/win-lose-report'
import AccountReport from '@/router/account-report'
import RoundSetting from '@/router/setting-round'
import Help from '@/router/help'
import Assistant from '@/router/assistant'


import ReportRouter from '@/router/report'
import RoundRouter from '@/router/round'

function configRoutes () {
  return [
    {
      path: '/',
      // name: 'Home',
      component: TheContainer,
      children: [
        {
          path: '',
          name: 'Dashboard',
          component: () => import('@/views/Dashboard')
        },
        // ReportRouter,
        // RoundRouter,

        ManageAccountRouter,
        AccountSettingRouter,
        SettingReportRouter,
        WinLoseReportRouter,
        AccountReport,
        RoundSetting,
        Help,
        Assistant,
        {
          path: '/logout',
          name: 'Logout',
          component: Logout
        }
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/404',
      name: 'Page404',
      component: Page404
    },
    {
      path: '/500',
      name: 'Page500',
      component: Page500
    }
  ]
}

router.beforeEach((to, from, next) => {
  const publicPages = ['Login'];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = localStorage.getItem(AppConfig.key.LoggedIn);

  if (authRequired && !loggedIn) {
    next({name: 'Login'});
  } else {
    next();
  }
});

export default router

export default {
  path: '/manage-account',
  component: { render (c) { return c('router-view') } },
  children: [
    {
      path: 'create/member',
      name: 'CreateMember',
      component: () => import('@/views/manage-account/CreateMember')
    },
    {
      path: 'create/agent',
      name: 'CreateAgent',
      component: () => import('@/views/manage-account/CreateAgent')
    },
    {
      path: 'member',
      component: { render (c) { return c('router-view') } },
      children: [{
        path: '',
        name: 'ManageAccount',
        component: () => import('@/views/manage-account/ManageAccount')
      }, {
        path: ':accountId([0-9a-f]{24})',
        name: 'ManageAccountMember',
        component: () => import('@/views/manage-account/ManageAccountMember')
      }]
    },
    {
      path: 'block',
      name: 'BlockAccount',
      component: () => import('@/views/manage-account/BlockAccount')
    },
    {
      path: 'setting',
      name: 'NewAccountSetting',
      component: () => import('@/views/manage-account/NewAccountSetting')
    }
  ]
}

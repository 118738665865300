const AppConfig = {
  key: {
    LoggedIn: 'loggedInAgentRoseLottery',
    RefreshTokenKey: 'appRefreshTokenRoseLottery',
    TokenKey: 'appTokenRoseLottery',
    GlobalMarketKey: 'SelectedGlobalMarketRoseLottery'
  }
}

export default AppConfig;

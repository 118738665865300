export default {
  path: 'help',
  component: { render (c) { return c('router-view') } },
  children: [
    {
      path: '',
      name: 'Help',
      component: () => import('@/views/helps/Help')
    }
  ]
}

export default {
  path: 'account-report',
  component: { render (c) { return c('router-view') } },
  children: [
    {
      path: 'finance',
      name: 'AccountFinance',
      component: () => import('@/views/account-report/AccountFinance')
    },
    {
      path: 'finance-member',
      component: { render (c) { return c('router-view') } },
      children: [{
        path: '',
        name: 'FinanceMembers',
        component: () => import('@/views/account-report/FinanceMembers')
      }, {
        path: ':accountId([0-9a-f]{24})',
        name: 'FinanceMember',
        component: () => import('@/views/account-report/FinanceMember')
      }]
    },
    {
      path: 'log',
      name: 'AccountLogs',
      component: () => import('@/views/account-report/AccountLogs')
    }
  ]
}

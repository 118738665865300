<template>
  <span :class="roleStyle">{{role}}</span>
</template>
<script>
import { Roles } from '@/configs/account.config'

export default {
  name: 'AccountRole',
  props: ['role'],
  computed: {
    roleStyle() {
      const config = Roles.find((r)=>{
        return r.code === this.role
      })
      return config?.class || 'badge badge-secondary'
    }
  }
}
</script>

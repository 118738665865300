export default {
  path: 'assistant',
  component: { render (c) { return c('router-view') } },
  children: [
    {
      path: 'all',
      name: 'Assistants',
      component: () => import('@/views/assistant/Assistants')
    }, {
      path: 'create',
      name: 'CreateAssistant',
      component: () => import('@/views/assistant/CreateAssistant')
    }, {
      path: 'setting',
      name: 'SettingAssistant',
      component: () => import('@/views/assistant/SettingAssistant')
    }
  ]
}

import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import AppConfig from '@/configs/app.config.js'
import AuthService from '@/services/authService'
import SystemConfigService from '@/services/SystemConfigService'
import Auth from '@/helpers/auth'
import Swal from 'sweetalert2'

const cacheGlobalMarket = localStorage.getItem(AppConfig.key.GlobalMarketKey)
const globalMarket = JSON.parse(cacheGlobalMarket) || null
// console.log('globalMarket', cacheGlobalMarket)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  isDataLoading: false,
  userProfile: {
    role: '',
    userRole: '',
    username: '',
    firstName: '',
    lastName: '',
    tel: '',
    lineId: '',
    status: '',

    // ค่าสามารถเพิ่มเอเย่นต์ได้หรือเปล่า ใช้สำหรับซ่อมเมนู "เพิ่มเอเย่นต์"
    canAddAgent: false
  },
  account: {
    _id: null,
    balance: 0,
    member: 0,
    online: 0,
    news: null,
    permission: {}
  },
  markets: [],
  marketGroups: [],
  marketPayrates: [],
  accountLevels: [],
  globalMarket: globalMarket || {
    groupId: null,
    marketId: null,
    marketType: null,
    imageIcon: null,
    round: {
      roundId: null,
      roundDate: null
    }
  }
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  updateLoadingStatus(state, isLoading) {
    state.isDataLoading = isLoading
  },
  updateProfile (state, profile) {
    state.userProfile = {
      ...state.userProfile,
      ...profile
    }
  },
  updateBalance (state, balance) {
    state.account.balance = balance
  },
  updateAccount (state, account) {
    state.account = {
      ...state.account,
      ...account
    }
  },
  updateMarkets(state, markets) {
    state.markets = markets
  },
  updateMarketGroup(state, groups) {
    state.marketGroups = groups
    if(!state.globalMarket.groupId) {
      const activeGroups = groups.filter(group=>group.status === 'Active')
      if(activeGroups.length > 0) {
        state.globalMarket.groupId = activeGroups[0]._id
      }
    }
  },
  updateMarketPayrate(state, rates) {
    state.marketPayrates = rates
  },
  updateAccountLevel(state, levels) {
    state.accountLevels = levels
  },
  updateGlobalMarket(state, opts) {
    state.globalMarket = {
      ...state.globalMarket,
      ...opts
    }
    localStorage.setItem(AppConfig.key.GlobalMarketKey, JSON.stringify(state.globalMarket))
  },
  resetNews(state) {
    state.account.news = null
  }
}

const actions = {
  reloadProfile({ commit }) {
    AuthService.getProfile()
    .then((response) => {
      console.log('profile', response)
      if(response.success) {
        commit('updateProfile', response.data)
      }
    })
    .catch((err)=>{
      console.log('profile-error', err)
    })
  },
  reloadBalance({ commit }) {
    AuthService.getBalance()
    .then((response) => {
      console.log('balance', response)
      if(response.success) {
        commit('updateBalance', response.data)
      }
    })
  },
  reloadAccount({ commit }) {
    AuthService.getAccount()
    .then((response) => {
      if(response.success) {
        commit('updateAccount', response.data)
      }else{
        throw new Error(response)
      }
    })
  },
  reloadMarkets({ commit }) {
    console.log('reloadMarketGroups!!!')
    SystemConfigService.getMarkets()
    .then((response)=>{
      if(response.success) {
        commit('updateMarkets', response.data)
      }else{
        throw new Error(response?.message)
      }
    })
    .catch((err)=>{
      console.log(err.message)
    })
  },
  reloadMarketGroups({ commit }) {
    console.log('reloadMarketGroups!!!')
    SystemConfigService.getGroups()
    .then((response)=>{
      if(response.success) {
        commit('updateMarketGroup', response.data)
      }else{
        throw new Error(response?.message)
      }
    })
    .catch((err)=>{
      console.log(err.message)
    })
  },
  reloadMarketPayrates({ commit }) {
    SystemConfigService.getPayRates()
    .then((response)=>{
      if(response.success) {
        commit('updateMarketPayrate', response.data)
      }else{
        throw new Error(response?.message)
      }
    })
    .catch((err)=>{
      console.log(err.message)
    })
  },
  reloadAccountLevels({ commit }) {
    SystemConfigService.getAgentLevels()
    .then((response)=>{
      if(response.success) {
        commit('updateAccountLevel', response.data)
      }else{
        throw new Error(response?.data)
      }
    })
    .catch((err)=>{
      console.log(err.message)
    })
  }
}

export default new Vuex.Store({
  state,
  mutations,
  actions
})
